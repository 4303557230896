// import logoDark from "../images/finalgo.png"
import logoDark from "../images/natnepal/logo_4.png"
import aboutThree1 from "../images/natnepal/handiCraft/DSC_3338.jpg"

import projectImage1 from "../images/natnepal/handiCraft/DSC_5389.jpg"
import projectImage2 from "../images/natnepal/handiCraft/DSC_3320.jpg"
import projectImage3 from "../images/natnepal/handiCraft/DSC_3281.jpg"
import errorImage from "../images/404.png"
import progressImage1 from "../images/what-we-do-2-11.jpg"
// import sliderOne1 from "../images/natnepal/handiCraft/DSC_3310.JPG"
import sliderOne1 from "../images/natnepal/handiCraft/DSC_3310.jpg"
import sliderOne2 from "../images/natnepal/handiCraft/DSC_3286.jpg"
import sliderOne3 from "../images/natnepal/handiCraft/DSC_3340.jpg"
import sliderOne4 from "../images/natnepal/handiCraft/DSC_3342.jpg"
import sliderOne5 from "../images/natnepal/handiCraft/DSC_3354.jpg"
import sliderOne6 from "../images/natnepal/handiCraft/DSC_3365.jpg"
import sliderOne7 from "../images/natnepal/handiCraft/DSC_3383.jpg"
import sliderOne8 from "../images/natnepal/handiCraft/DSC_3392.jpg"
import aboutTwo1 from "../images/natnepal/handiCraft/DSC_3392.jpg"
import clientImage1 from "../images/client1.png"
import clientImage2 from "../images/client2.png"
import clientImage3 from "../images/client3.jpg"
import clientImage4 from "../images/client-41.jpg"
import clientImage5 from "../images/client5.png"
import clientImage6 from "../images/client6.png"
import clientImage7 from "../images/client7.png"
import clientImage8 from "../images/client8.png"

export const Logo = {
  dark: logoDark,
}

export const NavLinksData = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/",
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2",
    //   },
    // ],
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/services",
  },
  {
    name: "Pricing",
    url: "/pricing",
  },
  {
    name: "Contact",
    url: "/contact",
  },
]

export const ContactFromData = {
  sectionContent: {
    title: "Send Message",
    subText: "Contact with us",
  },
}

export const mobileMenuData = {
  title: "Natnepal",
  description:
    "We do our own manufacturing of various woolen products. We have been manufacturing our Woolen knitwears, Felt products, handmade paper products, Silver Jewelry, etc. We do our own metal and wooden carving, make Thanka paintings. And as a result of which we take pride in our products and take much more orders with fulfillment guarantee than other exporters.",
}

export const SliderOneData = [
  {
    image: sliderOne1,
    // title: "One Stop \n Printing Solution",
    // subText: "We bring design together with technology",
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne2,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne3,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne4,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne5,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne6,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne7,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  {
    image: sliderOne8,
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
]

export const SliderTwoData = {
  image: "",
  posts: [
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing",
      },
    },
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing",
      },
    },
  ],
}

export const CallToActionData = {
  sectionContent: {
    subText: "Business woolen products",
    title: "Even More Good \n Stuff",
    textAlignment: "left",
  },
  lists: [
    "Woolen Knitwears",
    "HandiCrafts",
    "Services on Deadline",
    "Good User Experience",
  ],
  button: {
    label: "Learn More",
    url: "/services",
  },
}

export const AboutThreeData = {
  backgroundImage: aboutThree1,
  sectionContent: {
    subText: "Checkout our services",
    title: "Explore Our \n Services",
    textAlignment: "left",
  },
  content:
    "Unlike, most exporter who are retailers or wholesale suppliers, we do not put stocks form like any other manufacturers. We are a complete woolen product exporter with our own production line. And that is what separetes us from most woolen product suppliers of Nepal. Since, we are producing our own products, we take various types of specifications and designs on order to make the end products as per clients requirements.",
  button: {
    label: "Learn More",
    url: "/services",
  },
  phone: "+977-01-4988074",
  posts: [
    {
      title: "Woolen \n Sweater",
      icon: "printify-icon-t-shirt-with-square",
      url: "/services",
    },
    {
      title: "Woolen \n Gloves",
      icon: "printify-icon-big-box",
      url: "/services",
    },
    {
      title: "Woolen \n Socks",
      icon: "printify-icon-big-clip",
      url: "/services",
    },
    {
      title: "Knitted  \n Bag",
      icon: "printify-icon-shopping-paper-bag",
      url: "/services",
    },
  ],
}

export const AboutTwoData = {
  sectionContent: {
    subText: "Welcome to Natnepal",
    title: "Think it. Order it.",
    textAlignment: "left",
  },
  content:
    "When we say we’re not happy until you’re happy, we really do mean it. So whether it’s a typo or an image that didn’t print quite the way you hoped, we’ll do everything we can to \n fix it. \n \n We love great design and believe it can work wonders for every business. That’s why we make it simple to create beautiful, expertly crafted business stationery.",
  url: "/about",
  image: aboutTwo1,
}

export const ServiceTabOneData = [
  {
    title: AboutThreeData.posts[0].title,
    icon: AboutThreeData.posts[0].icon,
    content: {
      title: AboutThreeData.posts[0].title,
      subText: "Our services",
      content:
        "Choose the perfect woolen design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \nOriginal single-sided woolens (writeable on one side) that you can send without an envelope, Original double-sided \nwoolens (more impactful) and luxe woolens (extra thick and luxurious).",
    },
  },
  {
    title: AboutThreeData.posts[1].title,
    icon: AboutThreeData.posts[1].icon,
    content: {
      title: AboutThreeData.posts[1].title,
      subText: "Our services",
      content:
        "Choose the perfect woolen design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided woolens (writeable on one side) that you can send without an envelope, Original double-sided \n woolens (more impactful) and luxe woolens (extra thick and luxurious).",
    },
  },
  {
    title: AboutThreeData.posts[2].title,
    icon: AboutThreeData.posts[2].icon,
    content: {
      title: AboutThreeData.posts[2].title,
      subText: "Our services",
      content:
        "Choose the perfect woolen design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided woolens (writeable on one side) that you can send without an envelope, Original double-sided \n woolens (more impactful) and luxe woolens (extra thick and luxurious).",
    },
  },
  {
    title: AboutThreeData.posts[3].title,
    icon: AboutThreeData.posts[3].icon,
    content: {
      title: AboutThreeData.posts[3].title,
      subText: "Our services",
      content:
        "Choose the perfect woolen design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided woolens (writeable on one side) that you can send without an envelope, Original double-sided \n woolens (more impactful) and luxe woolens (extra thick and luxurious).",
    },
  },
  {
    title: "HandiCraft \n Services",
    icon: "printify-icon-white-sheets",
    content: {
      title: "HandiCraft \n Services",
      subText: "Our services",
      content:
        "Choose the perfect woolen design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided woolens (writeable on one side) that you can send without an envelope, Original double-sided \n woolens (more impactful) and luxe woolens (extra thick and luxurious).",
    },
  },
]

export const ProjectOneData = [
  {
    image: projectImage1,
    category: "",
    title: "Wide range of Woolen Services",
    url: "/services",
  },
  {
    image: projectImage2,
    category: "woolen",
    title: "With No Compromise on Quality",
    url: "/services",
  },
  {
    image: projectImage3,
    category: "woolen",
    title: "Best Price and Quick Service",
    url: "/services",
  },
  // {
  //   image: projectImage4,
  //   category: "printing",
  //   title: "Square Paper Book",
  //   url: "/services",
  // },
  // {
  //   image: projectImage5,
  //   category: "printing",
  //   title: "Book Copy",
  //   url: "/project-details",
  // },
  // {
  //   image: projectImage6,
  //   category: "printing",
  //   title: "C Creative Mess",
  //   url: "/project-details",
  // },
]
export const errorData = {
  title: "Oops! This page is not available",
  text: "Please go back to home and try to find out once again.",
  image: errorImage,
}

export const ProgressData = {
  sectionImage: progressImage1,
  sectionContent: {
    title: "You’ll get a \n perfect fit for \n your business.",
    subText: "What we do",
    content:
      "Handing out a business card is often the first impression people take of your business, so you need to get it right. Using your own photography or artwork to create custom business cards can help, and PRINTIFY makes the process easy with our simple online tools and templates.",
  },
  posts: [
    {
      title: "Flyer \n Printing",
      price: "9.99",
      percentCount: "90",
    },
    {
      title: "Sticker \n Printing",
      price: "299.99",
      percentCount: "70",
    },
  ],
}

export const PricingData = {
  sectionContent: {
    title: "Pricing",
    subText: "Choose best price",
    content:
      "For Manjushree, print is not just a piece of paper but a powerful business tool. We take the best care of your print job beginning from placing an order to design review, print quality. Price varies on Quanity though.",
  },
  posts: [
    {
      name: "for Business Card",
      price: "1.00",
      icon: "printify-icon-continuous-feed-paper",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored & Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
    {
      name: "for Brochure",
      price: "35.00",
      icon: "printify-icon-brochure-folded",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored &  Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
    {
      name: "for LetterHead",
      price: "45.00",
      icon: "printify-icon-white-sheets",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored &  Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
  ],
}

export const ClientCarouselData = [
  {
    image: clientImage1,
  },
  {
    image: clientImage2,
  },
  {
    image: clientImage3,
  },
  {
    image: clientImage4,
  },
  {
    image: clientImage5,
  },
  {
    image: clientImage6,
  },
  {
    image: clientImage7,
  },
  {
    image: clientImage8,
  },
]

export const ContactInfosData = {
  sectionContent: {
    title: "Details",
    subText: "Contact info",
    textAlignment: "center",
  },
  posts: [
    {
      title: "Address",
      text: "Swayambhu, Kathmandu, Nepal",
    },
    {
      title: "Phone",
      text: "Local: +977-01-4988074  \n  Mobile: +977-9851027130",
    },
    {
      title: "Email",
      text: "natnepal@gmail.com",
    },

    {
      title: "Follow",
      socials: [
        {
          icon: "fa fa-facebook-f",
          url: "#",
        },
        {
          icon: "fa fa-linkedin-in",
          url: "#",
        },
        {
          icon: "fa fa-instagram",
          url: "#",
        },
      ],
    },
  ],
}

export const FooterAboutData = {
  title: "About Natnepal",
  text: mobileMenuData.description,
}

export const FooterLinksData = {
  title: "Explore",
  links: [
    {
      label: "About",
      url: "/about",
    },
    {
      label: "Contact",
      url: "/contact",
    },
    {
      label: "Our Services",
      url: "/services",
    },
    {
      label: "Plans & Pricing",
      url: "/pricing",
    },
  ],
}

export const FooterContactData = {
  title: "Contact",
  infos: [
    {
      text: "+977-9851027130",
      url: "tel:+977-9851027130",
    },
    {
      text: "natnepal@gmail.com",
      url: "mailto:natnepal@gmail.com",
    },
    {
      text: "Swayambhu, \n Kathmandu, Nepal",
      url: "https://www.google.com/maps/dir/27.7196601,85.2953517/Swayambhu,+Kathmandu/@27.7194756,85.2911205,15.81z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39eb18ece8135209:0xa82eb67cf4731fa8!2m2!1d85.2955242!2d27.7192103?entry=ttu",
    },
  ],
}

// export const FooterBottomData = {
//   social: [
//     {
//       icon: "fa fa-facebook-f",
//       url: "https://www.facebook.com/mmgraphicpress",
//     },
//     {
//       icon: "fa fa-linkedin-in",
//       url: "https://www.linkedin.com/company/manjushree-multi-graphic-press/",
//     },
//     {
//       icon: "fa fa-instagram",
//       url: "https://www.instagram.com/manjushreepress/",
//     },
//   ],
// }

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-facebook-f",
      url: "https://www.facebook.com/natnepal.com.np",
    },
    // {
    //   icon: "fa fa-linkedin-in",
    //   url: "https://www.linkedin.com/company/manjushree-multi-graphic-press/",
    // },
    // {
    //   icon: "fa fa-instagram",
    //   url: "https://www.instagram.com/manjushreepress/",
    // },
  ],
}
